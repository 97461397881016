export enum SettingsKeys {
  BOOK_FLOW_ACTION_TEXT = 'BOOK_FLOW_ACTION_TEXT',
  NO_BOOK_FLOW_ACTION_TEXT = 'NO_BOOK_FLOW_ACTION_TEXT',
  DISPLAY_IMAGE = 'DISPLAY_IMAGE',
  DISPLAY_TAG_LINE = 'DISPLAY_TAG_LINE',
  DISPLAY_DIVIDER = 'DISPLAY_DIVIDER',
  DISPLAY_PRICE = 'DISPLAY_PRICE',
  DISPLAY_DURATION = 'DISPLAY_DURATION',
  DISPLAY_START_DATE = 'DISPLAY_START_DATE',
  DISPLAY_DAYS_OFFERED = 'DISPLAY_DAYS_OFFERED',
  DISPLAY_BUTTON = 'DISPLAY_BUTTON',
  DISPLAY_MORE_INFO_LABEL = 'DISPLAY_MORE_INFO_LABEL',
  DISPLAY_MULTI_OFFERINGS_TITLE = 'DISPLAY_MULTI_OFFERINGS_TITLE',
  DISPLAY_CATEGORIES = 'DISPLAY_CATEGORIES',
  DISPLAY_SERVICE_DIVIDER = 'DISPLAY_SERVICE_DIVIDER',
  TEXT_ALIGNMENT = 'TEXT_ALIGNMENT',
  IMAGE_RESIZE_OPTION = 'IMAGE_RESIZE_OPTION',
  IMAGE_POSITION_OPTION = 'IMAGE_POSITION_OPTION',
  IMAGE_SHAPE_OPTION = 'IMAGE_SHAPE_OPTION',
  PIN_LOCATION = 'PIN_LOCATION',
  IMAGE_AND_TEXT_POSITION = 'IMAGE_AND_TEXT_POSITION',
  IMAGE_AND_TEXT_RATIO = 'IMAGE_AND_TEXT_RATIO',
  IMAGE_AND_TEXT_RATIO_IS_FLIPED = 'IMAGE_AND_TEXT_RATIO_IS_FLIPED',
  SELECTED_RESOURCES = 'SELECTED_RESOURCES',
  OFFERING_NAME_FONT = 'OFFERING_NAME_FONT',
  OFFERING_NAME_COLOR = 'OFFERING_NAME_COLOR',
  OFFERING_TAGLINE_FONT = 'OFFERING_TAGLINE_FONT',
  OFFERING_TAGLINE_COLOR = 'OFFERING_TAGLINE_COLOR',
  OFFERING_DETAILS_FONT = 'OFFERING_DETAILS_FONT',
  OFFERING_DETAILS_COLOR = 'OFFERING_DETAILS_COLOR',
  BACKGROUND_COLOR = 'BACKGROUND_COLOR',
  BORDER_COLOR = 'BORDER_COLOR',
  IMAGE_BACKGROUND_COLOR = 'IMAGE_BACKGROUND_COLOR',
  DIVIDER_COLOR = 'DIVIDER_COLOR',
  SERVICE_DIVIDER_COLOR = 'SERVICE_DIVIDER_COLOR',
  BORDER_WIDTH = 'BORDER_WIDTH',
  STRIP_BORDER_WIDTH = 'STRIP_BORDER_WIDTH',
  DIVIDER_WIDTH = 'DIVIDER_WIDTH',
  SERVICE_DIVIDER_WIDTH = 'SERVICE_DIVIDER_WIDTH',
  BUTTON_STYLE = 'BUTTON_STYLE',
  BUTTON_TEXT_FONT = 'BUTTON_TEXT_FONT',
  BUTTON_TEXT_COLOR_FILL = 'BUTTON_TEXT_COLOR_FILL',
  BUTTON_TEXT_COLOR_HOLE = 'BUTTON_TEXT_COLOR_HOLE',
  BUTTON_BORDER_COLOR = 'BUTTON_BORDER_COLOR',
  BUTTON_BACKGROUND_COLOR = 'BUTTON_BACKGROUND_COLOR',
  BUTTON_CORNER_RADIUS = 'BUTTON_CORNER_RADIUS',
  BUTTON_BORDER_WIDTH = 'BUTTON_BORDER_WIDTH',
  MULTI_OFFERINGS_TITLE_TEXT = 'MULTI_OFFERINGS_TITLE_TEXT',
  OFFERING_LIST_LAYOUT = 'OFFERINGS_LIST_LAYOUT',
  CATEGORY_NAME_FONT = 'CATEGORY_NAME_FONT',
  CATEGORY_NAME_COLOR = 'CATEGORY_NAME_COLOR',
  CATEGORY_DIVIDER_COLOR = 'CATEGORY_DIVIDER_COLOR',
  CATEGORY_SELECTED_UNDERLINE_COLOR = 'CATEGORY_SELECTED_UNDERLINE_COLOR',
  CATEGORY_ALIGNMENT = 'CATEGORY_ALIGNMENT',
  MULTI_OFFERINGS_TITLE_ALIGNMENT = 'MULTI_OFFERINGS_TITLE_ALIGNMENT',
  MULTI_OFFERINGS_TITLE_COLOR = 'MULTI_OFFERINGS_TITLE_COLOR',
  MULTI_OFFERINGS_TITLE_FONT = 'MULTI_OFFERINGS_TITLE_FONT',
  FIT_CATEGORY_WIDTH = 'FIT_CATEGORY_WIDTH',
  MULTI_OFFERINGS_BACKGROUND_COLOR = 'MULTI_OFFERINGS_BACKGROUND_COLOR',
  SPACE_BETWEEN_OFFERINGS = 'SPACE_BETWEEN_OFFERINGS',
  STRIP_SPACE_BETWEEN_OFFERINGS = 'STRIP_SPACE_BETWEEN_OFFERINGS',
  LAYOUT_CARDS_PER_ROW = 'LAYOUT_CARDS_PER_ROW',
  CARDS_SPACING = 'CARDS_SPACING',
  STRIP_SIDE_PADDING = 'LAYOUT_SIDE_PADDING',
  GRID_SIDE_PADDING = 'INFO_SIDE_PADDING',
  CLASSIC_SIDE_PADDING = 'CLASSIC_SIDE_PADDING',
  OVERLAPPING_SIDE_PADDING = 'OVERLAPPING_SIDE_PADDING',
  STRIP_VERTICAL_PADDING = 'LAYOUT_VERTICAL_PADDING',
  GRID_VERTICAL_PADDING = 'INFO_VERTICAL_PADDING',
  CLASSIC_VERTICAL_PADDING = 'CLASSIC_VERTICAL_PADDING',
  OVERLAPPING_VERTICAL_PADDING = 'OVERLAPPING_VERTICAL_PADDING',
  STRIP_SIDE_PADDING_UNIT = 'LAYOUT_SIDE_PADDING_UNIT',
  GRID_SIDE_PADDING_UNIT = 'INFO_SIDE_PADDING_UNIT',
  CLASSIC_SIDE_PADDING_UNIT = 'CLASSIC_SIDE_PADDING_UNIT',
  OVERLAPPING_SIDE_PADDING_UNIT = 'OVERLAPPING_SIDE_PADDING_UNIT',
  STRIP_VERTICAL_PADDING_UNIT = 'LAYOUT_VERTICAL_PADDING_UNIT',
  GRID_VERTICAL_PADDING_UNIT = 'INFO_VERTICAL_PADDING_UNIT',
  CLASSIC_VERTICAL_PADDING_UNIT = 'CLASSIC_VERTICAL_PADDING_UNIT',
  OVERLAPPING_VERTICAL_PADDING_UNIT = 'OVERLAPPING_VERTICAL_PADDING_UNIT',
}

export enum MobileSettingsKeys {
  MOBILE_CARDS_SPACING = 'MOBILE_CARDS_SPACING',
  MOBILE_IMAGE_RESIZE_OPTION = 'MOBILE_IMAGE_RESIZE_OPTION',
  MOBILE_IMAGE_SHAPE_OPTION = 'MOBILE_IMAGE_SHAPE_OPTION',
  MOBILE_TEXT_ALIGNMENT = 'MOBILE_TEXT_ALIGNMENT',
  MOBILE_DISPLAY_IMAGE = 'MOBILE_DISPLAY_IMAGE',
  MOBILE_DISPLAY_TAG_LINE = 'MOBILE_DISPLAY_TAG_LINE',
  MOBILE_DISPLAY_DIVIDER = 'MOBILE_DISPLAY_DIVIDER',
  MOBILE_DISPLAY_PRICE = 'MOBILE_DISPLAY_PRICE',
  MOBILE_DISPLAY_DURATION = 'MOBILE_DISPLAY_DURATION',
  MOBILE_DISPLAY_START_DATE = 'MOBILE_DISPLAY_START_DATE',
  MOBILE_DISPLAY_DAYS_OFFERED = 'MOBILE_DISPLAY_DAYS_OFFERED',
  MOBILE_DISPLAY_BUTTON = 'MOBILE_DISPLAY_BUTTON',
  MOBILE_DISPLAY_MORE_INFO_LABEL = 'MOBILE_DISPLAY_MORE_INFO_LABEL',
  MOBILE_DIVIDER_WIDTH = 'MOBILE_DIVIDER_WIDTH',
  MOBILE_BORDER_WIDTH = 'MOBILE_BORDER_WIDTH',
  MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE = 'MOBILE_MULTI_OFFERINGS_TITLE_FONT_SIZE',
  MOBILE_CATEGORY_NAME_FONT_SIZE = 'MOBILE_CATEGORY_NAME_FONT_SIZE',
  MOBILE_OFFERING_NAME_FONT_SIZE = 'MOBILE_OFFERING_NAME_FONT_SIZE',
  MOBILE_OFFERING_TAGLINE_FONT_SIZE = 'MOBILE_OFFERING_TAGLINE_FONT_SIZE',
  MOBILE_OFFERING_DETAILS_FONT_SIZE = 'MOBILE_OFFERING_DETAILS_FONT_SIZE',
  MOBILE_BUTTON_TEXT_FONT_SIZE = 'MOBILE_BUTTON_TEXT_FONT_SIZE',
  MOBILE_BUTTON_STYLE = 'MOBILE_BUTTON_STYLE',
  MOBILE_PIN_LOCATION = 'MOBILE_PIN_LOCATION',
  MOBILE_SIDE_PADDING = 'MOBILE_SIDE_PADDING',
  MOBILE_VERTICAL_PADDING = 'MOBILE_VERTICAL_PADDING',
  MOBILE_SIDE_PADDING_UNIT = 'MOBILE_SIDE_PADDING_UNIT',
  MOBILE_VERTICAL_PADDING_UNIT = 'MOBILE_VERTICAL_PADDING_UNIT',
}

/*
important please add all the font keys into this list fix for #SCHED-9559
*/
export const AllFontsInTheApp = [
  SettingsKeys.OFFERING_NAME_FONT,
  SettingsKeys.OFFERING_TAGLINE_FONT,
  SettingsKeys.OFFERING_DETAILS_FONT,
  SettingsKeys.BUTTON_TEXT_FONT,
  SettingsKeys.CATEGORY_NAME_FONT,
  SettingsKeys.MULTI_OFFERINGS_TITLE_FONT,
];

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum UnitOptions {
  PIXEL = 'px',
  PERCENT = '%',
}

export enum ImageResizeOptions {
  CROP = 'crop',
  FIT = 'fit',
}

export enum ImageShapeOptions {
  SQUARE = 'square',
  ROUND = 'round',
  RECTANGLE = 'rectangle',
}

export enum ImagePositionOptions {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum OfferingLayoutOptions {
  SIDE_BY_SIDE = 'SIDE_BY_SIDE',
  OVERLAPPING = 'OVERLAPPING',
}

export enum ImageAndTextRatioOptions {
  RATIO_50_50 = '50',
  RATIO_40_60 = '40',
  RATIO_30_70 = '30',
}

export enum ButtonStyleOptions {
  SQUARE_HOLE = 'SQUARE_HOLE',
  SQUARE_FILL = 'SQUARE_FILL',
  CIRCLE_HOLE = 'CIRCLE_HOLE',
  CIRCLE_FILL = 'CIRCLE_FILL',
}

export enum DockLocationOptions {
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  BOTTOM = 'BOTTOM',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP = 'TOP',
}

export enum OfferingListLayoutOptions {
  CLASSIC = 'CLASSIC',
  OVERLAPPING = 'OVERLAPPING',
  STRIP = 'STRIP',
  GRID = 'GRID',
  MOBILE = 'MOBILE',
}
